<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-alert
        v-show="copyAlert"
        type="info"
        text
      >
        {{ msgClaveCopiado }}
      </v-alert>
      <base-card>
        <v-card-text>
          <v-card-title>
            Lista de Firmas Electrónicas
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="listFirmas"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Activo`]="{ item }">
              <v-icon
                v-if="item.Activo === 1"
                color="success darken-2"
                right
                class="mr-2"
              >
                mdi-account-check
              </v-icon>
              <v-icon
                v-else
                color="warning darken-2"
                right
                class="mr-2"
              >
                mdi-account-cancel
              </v-icon>
            </template>
            <template v-slot:[`item.Clave`]="{ item }">
              <v-icon
                v-clipboard:copy="item.Clave"
                v-clipboard:success="onCopy"
                style="cursor:pointer;"
                color="info darken-3"
                class="mr-2"
              >
                mdi-content-copy
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    name: 'ShowFirmas',
    data () {
      return {
        show: false,
        search: '',
        alertStatus: true,
        msgResponsePassword: '',
        password: '',
        progress: false,
        copyAlert: false,
        msgClaveCopiado: 'Clave Copiada',
        headers: [
          { text: 'Cliente', value: 'NombreCompania' },
          { text: 'Url Firma', value: 'UrlUbicacion' },
          { text: 'Clave', value: 'Clave', align: 'center'  },
          { text: 'Vigencia Desde', value: 'VigenciaDesde' },
          { text: 'Vigencia Hasta', value: 'VigenciaHasta' },
          { text: 'Estado', value: 'Activo', align: 'center' },
          { text: 'Fecha Creación   ', value: 'FechaCreacion' },
          { text: 'Fecha Actualización', value: 'FechaActualizacion' },
        ],
      }
    },
    computed: {
      ...mapState('firmas', [
        'listFirmas',
      ]),
    },
    created () {
      this.getListFirmas()
    },

    methods: {
      async getListFirmas () {
        await this.$store.dispatch('firmas/getListFirmas')
      },
      onCopy (e) {
        this.generateAlertCopy()
      },
      generateAlertCopy () {
        this.copyAlert = true
        setTimeout(() => {
          this.copyAlert = false
        }, 2000)
      },
    },
  }
</script>

<style scoped>

</style>
